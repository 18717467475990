.div-tabela-formulario-padrao {
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 10px;
}

.tabela-formulario-padrao tr td {
    padding-top: 5px;
    padding-bottom: 2px;
}

.tabela-formulario-padrao .codigo {
    min-width: 60px;
    max-width: 60px;
}

.tabela-formulario-padrao .acoes {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    padding-top: 2px;
}

.tabela-formulario-padrao tr {
    height: auto;
    min-height: 30px;
    /*max-height: 30px;*/
}