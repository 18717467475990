.sub-cadastro .codigo {
    min-width: 60px;
    max-width: 60px;
}

.sub-cadastro .acoes {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    padding-top: 2px;
}

.sub-cadastro-btn-novo:disabled, .sub-cadastro-btn-novo[disabled=disabled], .sub-cadastro-btn-novo[disabled],
.sub-cadastro-btn-alterar:disabled, .sub-cadastro-btn-alterar[disabled=disabled], .sub-cadastro-btn-alterar[disabled],
.sub-cadastro-btn-excluir:disabled, .sub-cadastro-btn-excluir[disabled=disabled], .sub-cadastro-btn-excluir[disabled]
{    
    cursor: not-allowed !important; 
}