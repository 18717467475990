.login-logo {   
   margin: 0 auto 10px auto;
   margin-left: -20px;
   margin-right: -20px;
   margin-bottom: -100px;
   margin-top: -100px;
}

.div-login-logo {
   min-height: 130px;
   overflow: hidden;
}
