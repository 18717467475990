body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    position: fixed;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

#root {
    height: 100%;
    width: 100%;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.bold {
    font-weight: bold;
}

.upper {
    text-transform: uppercase;
}

table {
    table-layout: fixed;
    width: 100%;
}

table tr {
    display: flex;
    border: 0;
}

table th {
    font-weight: 600;
}

th,
td {
    padding: 3px;
    border: 0;
}

table > thead > tr > th,
table > thead > tr > td {
    background-color: #e9ecef;
}

table tr:first-child {
    border-color: rgb(206, 212, 218);
    border-top-width: 1px;
    border-style: solid;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

table tr:last-child {
    border-color: rgb(206, 212, 218);
    border-bottom-width: 1px;
    border-style: solid;
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

table tr th:first-child,
table tr td:first-child {
    border-color: rgb(206, 212, 218);
    border-left-width: 1px;
    border-style: solid;
}

table tr th:last-child,
table tr td:last-child {
    border-color: rgb(206, 212, 218);
    border-right-width: 1px;
    border-style: solid;
}

table tr th:not(first-child),
table tr td:not(first-child) {
    border-color: rgb(206, 212, 218);
    border-right-width: 1px;
    border-style: solid;
}

table tr:first-child > td:first-child {
    border-top-left-radius: 5px;
}

table tr:first-child > td:last-child {
    border-top-right-radius: 5px;
}

table tr:last-child > td:first-child {
    border-bottom-left-radius: 5px;
}

table tr:last-child > td:last-child {
    border-bottom-right-radius: 5px;
}

table tr {
    border-bottom-width: 1px;
    border-bottom-color: rgb(206, 212, 218);
    border-bottom-style: solid;
}

table thead tr:last-child > td:first-child {
    border-bottom-left-radius: 0px;
}

table thead tr:last-child > td:last-child {
    border-bottom-right-radius: 0px;
}

table thead tr:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.empty-table thead tr:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

table tbody tr:first-child > td:first-child {
    border-top-left-radius: 0px;
}

table tbody tr:first-child > td:last-child {
    border-top-right-radius: 0px;
}

table tbody tr:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.multilineTableData {
    word-break: break-all !important;
}

span, td {
    word-wrap: anywhere; 
}

.center {
    text-align: center;
}

td {   
    white-space: break-spaces;
}

.disabled-form .hide-when-readonly,
.disabled-form .acoes
{
    display: none !important;
}

.disabled-form input
{
    background-color: #e9ecef !important;
    border-top-right-radius: .25rem !important;
border-bottom-right-radius: .25rem !important;
}

.table-no-border,
.table-no-border tr, 
.table-no-border th, 
.table-no-border td
{
    border: 0 !important;
}

.no-wrap {
    word-wrap: normal; 
}