.home-logo {
    margin-top: -46%;
    margin-bottom: -46%;
    margin-left: -18px;
    margin-right: -18px;    
}

.div-home-logo {    
    width: 160px;
    height: 50px;
    overflow: hidden;
}